import voice1No from '../../../assets/sounds/child/se/SWSCRC1-18.mp3';
import voice2No from '../../../assets/sounds/child/se/SWSCRC1-19.mp3';
import voice3No from '../../../assets/sounds/child/se/SWSCRC1-20.mp3';
import voice4No from '../../../assets/sounds/child/se/SWSCRC1-21.mp3';
import voice5No from '../../../assets/sounds/child/se/SWSCRC1-22.mp3';
import voice6No from '../../../assets/sounds/child/se/SWSCRC1-23.mp3';
import voice7No from '../../../assets/sounds/child/se/SWSCRC1-24.mp3';
import voice8No from '../../../assets/sounds/child/se/SWSCRC1-25.mp3';
import voice9No from '../../../assets/sounds/child/se/SWSCRC1-26.mp3';
import voice10No from '../../../assets/sounds/child/se/SWSCRC1-27.mp3';
import voice11No from '../../../assets/sounds/child/se/SWSCRC1-28.mp3';
import voice12No from '../../../assets/sounds/child/se/SWSCRC1-29.mp3';
import voice13No from '../../../assets/sounds/child/se/SWSCRC1-30.mp3';
import voice14No from '../../../assets/sounds/child/se/SWSCRC1-31.mp3';
import voice15No from '../../../assets/sounds/child/se/SWSCRC1-32.mp3';
import voice16No from '../../../assets/sounds/child/se/SWSCRC1-33.mp3';
import voice17No from '../../../assets/sounds/child/se/SWSCRC1-34.mp3';
import voice18No from '../../../assets/sounds/child/se/SWSCRC1-35.mp3';
import voice19No from '../../../assets/sounds/child/se/SWSCRC1-36.mp3';
import voice20No from '../../../assets/sounds/child/se/SWSCRC1-37.mp3';
import voice21No from '../../../assets/sounds/child/se/SWSCRC1-38.mp3';

import stege from '../assets/stege-min.png';
import tupp from '../assets/tupp-min.png';
import heart from '../assets/heart-min.png';
import steg from '../assets/steg-min.png';
import grasshoppa from '../assets/grasshoppa-min.png';
import thread from '../assets/Garn-min.PNG';
import car from '../assets/Bil-min.PNG';
import jacket from '../assets/jacka-min.png';
import train from '../assets/tåg-min.png';
import trainvagen from '../assets/tågvagn-min.png';
import pencil from '../assets/Blyant-min.PNG';
import ten from '../assets/10-min.png';
import arm from '../assets/arm-min.png';
import six from '../assets/Seks-min.PNG';
import cherry from '../assets/körsbär-min.png';
import sock from '../assets/Sokk-min.PNG';
import shoe from '../assets/Sko-min.PNG';
import sil from '../assets/sil-min.png';
import fish from '../assets/fisk-min.png';
import sylt from '../assets/sylt-min.png';
import mouse from '../assets/Mus-min.PNG';
import sky from '../assets/Sky-min.PNG';
import onion from '../assets/Løk-min.PNG';
import roof from '../assets/Tak-min.PNG';
import castle from '../assets/slott-min.png';
import lock from '../assets/Lock-min.PNG';
import seagull from '../assets/mås-min.png';
import tuggummi from '../assets/tuggummi-min.png';
import lamb from '../assets/Lam-min.PNG';
import wallclock from '../assets/väggklocka-min.png';
import tarna from '../assets/tärna-min.png';
import wall from '../assets/mur.png';
import suger from '../assets/socker-min.png';
import cat from '../assets/cat-min.PNG';
import larv from '../assets/larv-min.png';
import alarm from '../assets/larm-min.png';
import comb from '../assets/Kam-min.PNG';
import porrage from '../assets/Grøt-min.PNG';
import stek from '../assets/stek-min.png';
import sax from '../assets/sax-min.png';
import kex from '../assets/kex-min.png';
import baby from '../assets/baby-min.png';
import sun from '../assets/Sol-min.PNG';
import belt from '../assets/skärp-min.png';
import ruler from '../assets/linjal-min.png';
import seven from '../assets/Sju-min.PNG';
import calculator from '../assets/miniräknara-min.png';
import svan from '../assets/svan-min.png';
import gren from '../assets/gren-min.png';
import tand from '../assets/tand-min.png';
import screen from '../assets/skärm-min.png';

import book from '../assets/Bok-min.PNG';
import sten from '../assets/sten-min.png';
import blue from '../assets/blue-min.png';
import byxa from '../assets/byxa-min.png';
import camel from '../assets/Kamel-min.PNG';
import pot from '../assets/kanna-min.png';
import kanin from '../assets/kanin-min.png';
import cinamon from '../assets/Kanel-min.PNG';
import coat from '../assets/kappa-min.png';
import house from '../assets/Hus-min.PNG';
import tass from '../assets/tass-min.png';
import goose from '../assets/gås-min.png';
import puppy from '../assets/Hund-min.PNG';
import hatt from '../assets/Hatt-min.PNG';
import helmet from '../assets/hjälm-min.png';
import barn from '../assets/Barn-min.PNG';
import tongue from '../assets/tongue-min.png';
import ship from '../assets/skepp-min.png';
import lice from '../assets/Lus-min.PNG';
import brain from '../assets/brain-min.png';
import karna from '../assets/kjerne-min.png';
import hand from '../assets/Hand-min.PNG';
import gam from '../assets/gam-min.png';
import bag from '../assets/påse-min.png';
import seal from '../assets/säl-min.png';
import wheel from '../assets/hjul-min.png';
import sack from '../assets/säck-min.png';
import number from '../assets/Tall-min.PNG';
import gran from '../assets/gran-min.png';
import garbage from '../assets/skråp-min.png';
import tunna from '../assets/tunna-min.png';
import dove from '../assets/duva-min.png';
import doll from '../assets/docka-min.png';
import bed from '../assets/säng-min.png';
import matchbox from '../assets/tändsticksask-min.png';
import munIMG from '../assets/mun-min.png';
import green from '../assets/green-min.png';
import yellow from '../assets/yellow-min.png';
import red from '../assets/red-min.png';
import purple from '../assets/purple-min.png';
import cow from '../assets/Ku-min.PNG';
import bark from '../assets/bark-min.png';
import bulb from '../assets/Lys-min.PNG';
import mitten from '../assets/Votter-min.PNG';
import starterTesten from '../../../assets/sounds/child/se/SWSCRC1-17.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'lys',
		},
		{
			word: 'lese',
		},
		{
			word: 'katt',
		},
		{
			isCorrect: true,
			word: 'kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: barn,
		text: 'Byt ut /b/ mot /g/',
		mainSound: voice1No,
		choices: [
			{
				word: 'garn',

				img: thread,
			},
			{
				word: 'bark',

				img: bark,
			},
			{
				word: 'bäbis',

				img: baby,
			},
			{
				word: 'gam',

				img: gam,
			},
		],
	},
];
export const assignment2 = [
	{
		id: 2,
		mainImg: mouse,
		text: 'Byt ut /m/ mot /h/',
		mainSound: voice2No,
		choices: [
			{
				word: 'ljus',

				img: bulb,
			},
			{
				word: 'hus',

				img: house,
			},
			{
				word: 'katt',

				img: cat,
			},
			{
				word: 'hund',

				img: puppy,
			},
		],
	},
];
export const assignment3 = [
	{
		id: 3,
		mainImg: lice,
		text: 'Byt ut /l/ mot /lj/',
		mainSound: voice3No,
		choices: [
			{
				word: 'hus',

				img: house,
			},
			{
				word: 'gräshoppa',

				img: grasshoppa,
			},
			{
				word: 'hjul',

				img: wheel,
			},
			{
				word: 'ljus ',

				img: bulb,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: brain,
		text: 'Byt ut /j/ mot /tj/',
		mainSound: voice4No,
		choices: [
			{
				word: 'kärna',

				img: karna,
			},
			{
				word: 'tärna',

				img: tarna,
			},
			{
				word: 'körsbär',

				img: cherry,
			},
			{
				word: 'hjärta',

				img: heart,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: train,
		text: 'Byt ut /l/ mot /b/',
		mainSound: voice5No,
		choices: [
			{
				word: 'lök',

				img: onion,
			},
			{
				word: 'tågvagn',

				img: trainvagen,
			},
			{
				word: 'bil',

				img: car,
			},
			{
				word: 'bok',

				img: book,
			},
		],
	},
];
export const assignment6 = [
	{
		id: 6,
		mainImg: goose,
		text: 'Byt ut /g/ mot /l/',
		mainSound: voice6No,
		choices: [
			{
				word: 'mås',

				img: seagull,
			},
			{
				word: 'hus',

				img: house,
			},
			{
				word: 'lås',

				img: lock,
			},
			{
				word: 'lus',

				img: lice,
			},
		],
	},
];
export const assignment7 = [
	{
		id: 7,
		mainImg: sock,
		text: 'Byt ut /s/ mot /d/',
		mainSound: voice7No,
		choices: [
			{
				word: 'docka',

				img: doll,
			},
			{
				word: 'socker',

				img: suger,
			},
			{
				word: 'sko',

				img: shoe,
			},
			{
				word: 'duva',

				img: dove,
			},
		],
	},
];
export const assignment8 = [
	{
		id: 8,
		mainImg: puppy,
		text: 'Byt ut /u/ mot /a/',
		mainSound: voice8No,
		choices: [
			{
				word: 'mun',

				img: munIMG,
			},
			{
				word: 'hand',

				img: hand,
			},
			{
				word: 'hatt',

				img: hatt,
			},
			{
				word: 'tass',

				img: tass,
			},
		],
	},
];
export const assignment9 = [
	{
		id: 9,
		mainImg: cinamon,
		text: 'Byt ut /n/ mot /m/',
		mainSound: voice9No,
		choices: [
			{
				word: 'kanin',

				img: kanin,
			},
			{
				word: 'kam',

				img: comb,
			},
			{
				word: 'kamel',

				img: camel,
			},
			{
				word: 'gröt',

				img: porrage,
			},
		],
	},
];
export const assignment10 = [
	{
		id: 10,
		mainImg: six,
		text: 'Byt ut /e/ mot /a/',
		mainSound: voice10No,
		choices: [
			{
				word: 'sax',

				img: sax,
			},
			{
				word: 'kex',

				img: kex,
			},
			{
				word: 'sju',

				img: seven,
			},
			{
				word: 'miniräknare',

				img: calculator,
			},
		],
	},
];
export const assignment11 = [
	{
		id: 11,
		mainImg: seal,
		text: 'Byt ut /ä/ mot /o/',
		mainSound: voice11No,
		choices: [
			{
				word: 'sil',

				img: sil,
			},
			{
				word: 'sol',

				img: sun,
			},
			{
				word: 'fisk',

				img: fish,
			},
			{
				word: 'sylt',

				img: sylt,
			},
		],
	},
];
export const assignment12 = [
	{
		id: 12,
		mainImg: coat,
		text: 'Byt ut /pp/ mot /nn/',
		mainSound: voice12No,
		choices: [
			{
				word: 'kamma',

				img: comb,
			},
			{
				word: 'jacka',

				img: jacket,
			},
			{
				word: 'kanin',

				img: kanin,
			},
			{
				word: 'kanna',

				img: pot,
			},
		],
	},
];
export const assignment13 = [
	{
		id: 13,
		mainImg: gran,
		text: 'Byt ut /a/ mot /ö/',
		mainSound: voice13No,
		choices: [
			{
				word: 'gren',

				img: gren,
			},
			{
				word: 'gul',

				img: yellow,
			},
			{
				word: 'grön',

				img: green,
			},
			{
				word: 'gröt',

				img: porrage,
			},
		],
	},
];
export const assignment14 = [
	{
		id: 14,
		mainImg: tunna,
		text: 'Byt ut /nn/ mot /ng/',
		mainSound: voice14No,
		choices: [
			{
				word: 'tunga',

				img: tongue,
			},
			{
				word: 'tupp',

				img: tupp,
			},
			{
				word: 'sophög',

				img: garbage,
			},
			{
				word: 'tuggummi',

				img: tuggummi,
			},
		],
	},
];
export const assignment15 = [
	{
		id: 15,
		mainImg: number,
		text: 'Byt ut /l/ mot /k/.',
		mainSound: voice15No,
		choices: [
			{
				word: 'tand',

				img: tand,
			},
			{
				word: 'linjal',

				img: ruler,
			},
			{
				word: 'tio',

				img: ten,
			},
			{
				word: 'tak',

				img: roof,
			},
		],
	},
];
export const assignment16 = [
	{
		id: 16,
		mainImg: wall,
		text: 'Byt ut /r/ mot /s/',
		mainSound: voice16No,
		choices: [
			{
				word: 'mun',

				img: munIMG,
			},
			{
				word: 'mus',

				img: mouse,
			},
			{
				word: 'mås',

				img: seagull,
			},
			{
				word: 'slott',

				img: castle,
			},
		],
	},
];
export const assignment17 = [
	{
		id: 17,
		mainImg: sack,
		text: 'Byt ut /ck/ mot /ng/',
		mainSound: voice17No,
		choices: [
			{
				word: 'säl',

				img: seal,
			},
			{
				word: 'säng',

				img: bed,
			},
			{
				word: 'socka',

				img: sock,
			},
			{
				word: 'påse',

				img: bag,
			},
		],
	},
];
export const assignment18 = [
	{
		id: 18,
		mainImg: alarm,
		text: 'Byt ut /m/ mot /v/',
		mainSound: voice18No,
		choices: [
			{
				word: 'lamm',

				img: lamb,
			},
			{
				word: 'väggklocka',

				img: wallclock,
			},
			{
				word: 'arm',

				img: arm,
			},
			{
				word: 'larv',

				img: larv,
			},
		],
	},
];
export const assignment19 = [
	{
		id: 19,
		mainImg: bulb,
		text: 'Byt ut /s/ mot /l/',
		mainSound: voice19No,
		choices: [
			{
				word: 'lus',

				img: lice,
			},
			{
				word: 'hjälm',

				img: helmet,
			},
			{
				word: 'hjul',

				img: wheel,
			},
			{
				word: 'tändsticksask',

				img: matchbox,
			},
		],
	},
];
export const assignment20 = [
	{
		id: 20,
		mainImg: belt,
		text: 'Byt ut /p/ mot /m/',
		mainSound: voice20No,
		choices: [
			{
				word: 'skärm',

				img: screen,
			},
			{
				word: 'skräp',

				img: garbage,
			},
			{
				word: 'byxa',

				img: byxa,
			},
			{
				word: 'skepp',

				img: ship,
			},
		],
	},
];
export const assignment21 = [
	{
		id: 21,
		mainImg: steg,
		text: 'Byt ut /g/ mot /n/',
		mainSound: voice21No,
		choices: [
			{
				word: 'stek',

				img: stek,
			},
			{
				word: 'stege',

				img: stege,
			},
			{
				word: 'svan',

				img: svan,
			},
			{
				word: 'sten',

				img: sten,
			},
		],
	},
];

export const assignment22 = [
	{
		id: 22,
		mainImg: puppy,
		mainSound: voice21No,
		choices: [
			{
				word: 'modil',

				img: red,
			},
			{
				word: 'vuks',

				img: green,
			},
			{
				word: 'fålk',

				img: blue,
			},
			{
				word: 'flynt',

				img: yellow,
			},
		],
	},
];
export const assignments = [
	{
		id: 2,
		mainImg: puppy,
		choices: [
			{
				word: 'modil',

				img: red,
			},
			{
				word: 'vuks',

				img: green,
			},
			{
				word: 'fålk',

				img: blue,
			},
			{
				word: 'flynt',

				img: yellow,
			},
		],
	},
	{
		id: 3,
		mainImg: cow,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: yellow,
			},
			{
				word: 'peano',

				img: sky,
			},
			{
				word: 'knain',

				img: blue,
			},
		],
	},
	{
		id: 4,
		mainImg: pencil,
		choices: [
			{
				word: 'vorr',

				img: purple,
			},
			{
				word: 'blastell',

				img: yellow,
			},
			{
				word: 'peano',

				img: sky,
			},

			{
				word: 'brillter',

				img: red,
			},
		],
	},
	{
		id: 5,
		mainImg: bulb,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: purple,
			},

			{
				word: 'knain',

				img: blue,
			},
			{
				word: 'brillter',

				img: yellow,
			},
		],
	},
	{
		id: 6,
		mainImg: mitten,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: yellow,
			},

			{
				word: 'knain',

				img: blue,
			},
			{
				word: 'brillter',

				img: red,
			},
		],
	},
];
