import React from 'react';
import {connect} from 'react-redux';

import {updateObject} from '../../utils/reducerUtils';
import strings from '../../utils/strings';

import classNames from 'classnames';
import AudioPlayer from '../../components/AudioPlayer';

import previewVideoNo from './assets/tutorial.mp4';
import previewVideoNn from './assets/tutorial.mp4';

import previewVideoSe from './assets/SE_Child_Ordkjede.mp4';
import previewVideoDe from './assets/DE_Ordkjede.mp4';

import {VARIATION} from '../../constants';

const whiteSpaceRegex = /\s+/g;

const stripWhiteSpace = (str) => str.replace(whiteSpaceRegex, '');
const removeDuplicateWhiteSpace = (str) => str.replace(whiteSpaceRegex, ' ');
const getNumberOfWhiteSpaces = (str) => {
	const matches = str.match(whiteSpaceRegex);

	if (!matches) {
		return 0;
	}

	return matches.length;
};

const WHITE_SPACE_LIMIT = 3;
const resultsGlobal = [];

class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();

		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};
		this.videoRefView = React.createRef();

		this.currentLang = language;
		this.startTheTestSound = require(`../../assets/sounds/${variation}/${language}/SWSCRC2-07.mp3`);
		this.audio = require(`../../assets/sounds/${variation}/${language}/swscrc2-2.mp3`);
		this.assignments =
			require(`../../assets/testdata/${variation}/${language}/wordchain-tutorial`).default;
		this.correctAnswerSound = require(`../../assets/sounds/${variation}/${language}/SWSCRC2-04.mp3`);

		this.video = previewVideoObj[language];
		this.audioRefs = {};

		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = assignment.text;
		}

		this.state = {
			results,
			renderStartSound: false,
		};
	}

	setResults(id, value, index) {
		const {results} = this.state;
		for (let i = 0; i < this.assignments.length; i++) {
			if (this.assignments[i].id === id) {
				if (this.assignments[i].answer === value) {
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
						isCorrect: true,
					};
				} else {
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
					};
				}
			}
		}

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {results} = this.state;

		let allAssignmentsCompleted = true;
		let allAssignmentsAreCorrect = false;
		let correctCounter = 0;
		for (const result in results) {
			if (results.hasOwnProperty(result)) {
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
					results[result]
				);
				if (numberOfWhiteSpaces !== WHITE_SPACE_LIMIT) {
					allAssignmentsCompleted = false;
					break;
				}
			}
		}
		for (let i = 0; i < resultsGlobal.length; i++) {
			if (
				resultsGlobal[i] &&
				resultsGlobal[i].hasOwnProperty('isCorrect')
			) {
				correctCounter = correctCounter + 1;
			}
		}

		if (allAssignmentsCompleted) {
			allAssignmentsAreCorrect = true;
		}
		let videoRef;

		return (
			<div className="lit-test lit-word-chain">
				<p>
					<AudioPlayer
						onErrorPlay={() => {
							console.log('Something went wrong');
						}}
						autoplay
						inline
						onStart={() => {
							setTimeout(() => {
								videoRef.play();
							}, 1000);

							const scrollActionTime =
								this.currentLang === 'se' ? 16000 : null;

							if (scrollActionTime) {
								setTimeout(() => {
									if (this.videoRefView.current) {
										this.videoRefView.current.scrollIntoView(
											{
												behavior: 'smooth',
												block: 'start',
											}
										);
									}
								}, scrollActionTime);
							}
						}}
						refs={this.audioRefs}
						src={this.audio}
					/>
					{strings.ordkjedetestTutorial}
				</p>
				<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{strings.howTo}</p>
					<video
						className="lit-preview__video"
						onClick={() => {
							videoRef.play();
						}}
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
				<div className="lit-wrapper--thin tutorial">
					<div className="lit-word-chains">
						{this.assignments.map((assignment, i) => {
							const {audio, answer, count, id, text} = assignment;

							let completedAssignment = false;
							let isCorrect = false;
							const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
								results[id]
							);
							if (numberOfWhiteSpaces === WHITE_SPACE_LIMIT) {
								completedAssignment = true;
								isCorrect = results[id] === answer;
							}

							const onChange = (ev) => {
								let value = ev.target.value;
								value = value.trim();
								value = removeDuplicateWhiteSpace(value);

								const hasUserEnteredOtherCharacters =
									stripWhiteSpace(value) !== text;
								const numberOfWhiteSpaces =
									getNumberOfWhiteSpaces(value);
								if (
									hasUserEnteredOtherCharacters ||
									numberOfWhiteSpaces > WHITE_SPACE_LIMIT
								) {
									return;
								}

								this.setResults(id, value, i);
							};
							const onFocus = (ev) => {
								const inputElement = document.getElementById(i);
								setTimeout(() => {
									inputElement.focus();
									inputElement.setSelectionRange(0, 0);
								}, 0);
							};
							return (
								<div className="input-wrapper" key={id}>
									<div
										className={classNames({
											'lit-input inline': true,
											completed: completedAssignment,
										})}
									>
										<label className="lit-input__label tiny lit-input__label">
											{/* 	{id} */}
										</label>
										<input
											id={i}
											autoFocus={i === 0}
											className={classNames({
												'lit-input__field': true,
											})}
											onChange={onChange}
											spellCheck={false}
											value={results[id]}
											onFocus={onFocus}
										/>
									</div>
									{this.currentLang === 'se' &&
										completedAssignment && (
											<p className="comment">
												{isCorrect ? (
													<span>
														{
															<AudioPlayer
																autoplay
																onErrorPlay={() => {
																	console.log(
																		'Something went wrong'
																	);
																}}
																inline
																refs={
																	this
																		.audioRefs
																}
																onComplete={() => {
																	if (
																		allAssignmentsAreCorrect
																	) {
																		this.setState(
																			{
																				renderStartSound: true,
																			}
																		);
																	}
																}}
																src={
																	this
																		.correctAnswerSound
																}
															/>
														}
														{
															strings.ordkjedeHeltRiktig
														}
													</span>
												) : (
													<span>
														<AudioPlayer
															autoplay
															onErrorPlay={() => {
																console.log(
																	'Something went wrong'
																);
															}}
															inline
															refs={
																this.audioRefs
															}
															onComplete={() => {
																if (
																	allAssignmentsAreCorrect
																) {
																	this.setState(
																		{
																			renderStartSound: true,
																		}
																	);
																}
															}}
															src={audio}
														/>

														{strings.formatString(
															strings.ordkjedeIkkeRiktig,
															count
														)}
														<strong>
															{answer
																.split(' ')
																.join(', ')}
														</strong>
													</span>
												)}
											</p>
										)}
									{this.currentLang !== 'se' &&
										completedAssignment &&
										i > 0 && (
											<p className="comment">
												{isCorrect ? (
													<span>
														{/* <AudioPlayer
														autoplay
														inline
													/> */}
														{
															strings.ordkjedeHeltRiktig
														}
													</span>
												) : (
													<span>
														<AudioPlayer
															// autoplay
															inline
															onErrorPlay={() => {
																console.log(
																	'Something went wrong'
																);
															}}
															refs={
																this.audioRefs
															}
															src={audio}
														/>

														{strings.formatString(
															strings.ordkjedeIkkeRiktig,
															count
														)}
														<strong>
															{answer
																.split(' ')
																.join(', ')}
														</strong>
													</span>
												)}
											</p>
										)}
								</div>
							);
						})}
					</div>
					{allAssignmentsCompleted && this.state.renderStartSound && (
						<p className="instruction">
							<AudioPlayer
								autoplay
								onErrorPlay={() => {
									console.log('Something went wrong');
								}}
								inline
								refs={this.audioRefs}
								src={this.startTheTestSound}
							/>
							{strings.ordkjedeStarterTesten}
						</p>
					)}
					{allAssignmentsCompleted && this.state.renderStartSound && (
						<button
							className="lit-btn bg-primary"
							onClick={() => onNextStep(results)}
						>
							{strings.start}
						</button>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
