import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';
import {isTablet} from 'react-device-detect';
import AudioPlayer from 'components/AudioPlayer';
import audioSe from '../../assets/sounds/child/se/SWSCRC1-01.mp3';
import audioNn from '../../assets/sounds/child/se/SWSCRC1-01.mp3';
import videoBiltoPil from './assets/video_byttelyd.mp4';
const languageObj = {
	nn: audioNn,
	se: audioSe,
};

class Intro extends React.Component {
	constructor(props) {
		super(props);
		this.audio = languageObj[strings.getLanguage()];
		this.video = videoBiltoPil;
		this.state = {
			playTheAudio: Boolean(isTablet === false),
		};
	}

	render() {
		const {onNextStep} = this.props;
		let videoRef;
		return (
			<div>
				{!this.state.playTheAudio && (
					<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								playTheAudio: true,
							});
						}}
					>
						Gå vidare
					</button>
				)}

				{this.state.playTheAudio && (
					<>
						<p>
							<AudioPlayer
								onStart={() => {
									videoRef.play();
								}}
								onErrorPlay={() => {
									console.log('Something went wrong');
								}}
								autoplay
								inline
								src={this.audio}
							/>
							{strings.formatString(
								strings.tulleordtesteneksempel1,
								<strong>b</strong>,
								<strong>bil</strong>,
								<strong>p</strong>,
								<strong>pil</strong>
							)}
						</p>
						<div className="lit-preview">
							<p className="lit-preview__label">{'Exempel'}</p>
							<video
								className="lit-preview__video"
								loop
								ref={(ref) => {
									videoRef = ref;
								}}
								src={this.video}
							/>
						</div>
						<p>{strings.tulleordtestenPractice}</p>
						<button
							className="lit-btn bg-primary"
							onClick={() => {
								this.props.onShowAvatar(false);
								onNextStep();
							}}
						>
							{strings.toPractice}
						</button>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
