import React from 'react';
import queryString from 'query-string';
import AudioPlayer from '../../components/AudioPlayer';

import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import {IS_NORMING_VERSION, VARIATION, LANGUAGE} from '../../constants';
import strings from '../../utils/strings';
const parsedSearch = queryString.parse(window.location.search);

const error = parsedSearch.error;

class LandingPage extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = LANGUAGE;
		strings.setLanguage(LANGUAGE);
		this.state = {
			testKey: '',
		};
		// this.audio = require(`../../assets/sounds/${variation}/de/welcome_sound.wav`);
	}
	componentDidMount() {
		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				/* document.getElementById(
					'start-fail-warn-ing'
				).innerHTML = `<img src=${warning} width="40px" height="30px">`; */
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					strings.warning_text_end;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);
	}

	getTestTitle() {
		switch (VARIATION) {
			case 'child':
				return strings.childtest;
			case 'youth':
				return strings.youthtest;
			case 'adult':
				return strings.adulttest;
			default:
				return strings.adulttest;
		}
	}

	render() {
		const {testKey} = this.state;

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="Literate Logo"
						/>
					</h1>

					<div className="right">
						<a className="lit-link" href="http://admin.dysmate.se">
							Administratör
						</a>
					</div>
				</header>
				<p className="lit-thirdPCsupport" id="result" />
				<div className="lit-thrid-party-settings">
					<p
						className="candidate-start-fail__instructions"
						style={{
							color: 'white',
							margin: 'auto',
							textAlign: 'center',
							fontSize: '18px',
						}}
					>
						<span
							id="start-fail-warn-ing"
							style={{justifyContent: 'center'}}
						></span>
						<span style={{color: 'Cyan', marginLeft: '2px'}}>
							<b>
								<span
									id="lit-site-warning"
									style={{marginLeft: '5px'}}
								></span>{' '}
							</b>
						</span>
						<em>
							<span id="lit-site-warning_text"></span>
						</em>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.dysmate.no/advarsel-2/"
						>
							<u style={{color: 'blue'}}>
								<span id="lit-site-settings-instructions"></span>
							</u>
						</a>
						<em>
							<span id="lit-site-warning_text_end"></span>
						</em>
					</p>
				</div>

				{IS_NORMING_VERSION && (
					<>
						<h1 className="lit-landing-page-title">
							{this.getTestTitle()}
						</h1>

						<p className="lit-landing-page-subtitle">
							{strings.normingversion}
						</p>

						<br></br>

						{strings.getLanguage() === 'de' && (
							<p className="lit-page-description">
								{/* <AudioPlayer inline src={this.audio} /> */}
								{strings.dysmate_welcome_text}
							</p>
						)}
					</>
				)}
				{!IS_NORMING_VERSION && (
					<>
						<h1 className="lit-page-title">
							{this.getTestTitle()}
						</h1>
					</>
				)}

				<p className="lit-page-description">
					{strings.writeCodeInField}
				</p>
				<div className="lit-wrapper--article">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							window.location.href = `/login?key=${testKey}`;
						}}
					>
						<div className="lit-input inline">
							<label className="lit-input__label tiny">
								{strings.yourCode}
							</label>
							<input
								className="lit-input__field"
								onChange={(event) => {
									const value = event.target.value.replace(
										/\s+/g,
										''
									);
									this.setState({
										testKey: value,
									});
								}}
								placeholder={strings.codeFieldPlaceholder}
								required
								type="text"
								value={testKey}
							/>
							<button className="lit-btn bg-secondary">
								{strings.start}
							</button>
						</div>
						{Boolean(error) && (
							<span>{strings.validKeyIsRequired}</span>
						)}
					</form>
				</div>
				<div>
					<iframe
						src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
						style={{display: 'none'}}
					/>
				</div>
			</div>
		);
	}
}

export default LandingPage;
