import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';

import {updateObject} from 'utils/reducerUtils';

import AudioPlayer from 'components/AudioPlayer';
import * as seTutorial from './languages/se-tutorial.js';

const tutorialObj = {
	se: seTutorial,
};

let count = 1;

class Tutorial extends React.Component {
	constructor(props) {
		super(props);
		const grade = Number(
			this.props.candidateGrade.grade || this.props.candidateGrade
		);
		this.tutorial = tutorialObj[strings.getLanguage()];

		this.tutorialAssignment = this.tutorial.tutorialAssignment;
		this.assignments = this.tutorial.assignments;
		this.exampleSound = this.tutorial.exampleSound;
		this.dinTurSound = this.tutorial.dinTurSound;
		this.starterTestenSound = this.tutorial.audioFilesSeUpperGrades;

		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			hasPlayedFirstAudio: false,
			results,
			counter: 1,
			choiceId: null,
			choiceValue: null,
			limit: 6,
			hideComment: false,
			showTestPanel: true,
			nextTutorial: false,
		};

		this.singleAssignment = {
			1: this.tutorial.assignment1,
			2: this.tutorial.assignment2,
			3: this.tutorial.assignment3,
			4: this.tutorial.assignment4,
			5: this.tutorial.assignment5,
			6: this.tutorial.assignment6,
			7: this.tutorial.assignment7,
			8: this.tutorial.assignment8,
		};

		this.audioRefs = {};
	}

	setResults(id, value /* remark */) {
		const {results} = this.state;
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			hideComment: false,
			choiceId: id,
			choiceValue: value,
			/* nextTutorial:remark */
		});
		this.scrollToTop();
	}
	updateCount() {
		const {results} = this.state;
		this.setState(
			{
				results: updateObject(results, {
					[this.state.choiceId]: this.state.choiceValue,
				}),
			},
			() => {
				this.setState({
					counter: count,
					hideComment: true,
					showTestPanel: false,
					nextTutorial: true,
				});
				this.props.onShowAvatar(true);
			}
		);

		/* this.scrollToTop(); */
	}
	scrollToTop = () => {
		window.scrollTo({
			top: 169,
			behavior: 'smooth',
		});
	};

	render() {
		const {onNextStep} = this.props;
		this.scrollToTop();
		const grade = Number(
			this.props.candidateGrade.grade || this.props.candidateGrade
		);
		const tulleordStarterTestenText =
			strings.tulleordStarterTestenUpperGrade;

		const {hasPlayedFirstAudio, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);

		const isNextButtonVisible = count === 4;

		return (
			<div className="lit-test">
				<div className="lit-tulleord lit-tulleord__tutorial">
					{this.singleAssignment[this.state.counter].map(
						(assignment) => {
							const {id, choices} = assignment;

							const currentAssignmentValue = results[id];
							let comment = null;
							for (const choice of choices) {
								if (id === 7) break;
								if (
									currentAssignmentValue === choice.word &&
									!this.state.hideComment
								) {
									comment = (
										<p className="instruction">
											<AudioPlayer
												autoplay
												inline
												onErrorPlay={() => {
													console.log(
														'Something went wrong'
													);
												}}
												onComplete={() => {
													if (choice.remark) {
														count = count + 1;
														this.updateCount();
													}
												}}
												ref={(ref) => {
													this.narDuStarterAudioPlayer =
														ref;
												}}
												refs={this.audioRefs}
												src={choice.sound}
											/>
											{choice.comment}
										</p>
									);
								}
							}
							if (this.state.showTestPanel && count < 4) {
								return (
									<div className="lit-words-wrapper" key={id}>
										<div className="lit-single-word">
											<p className="instruction">
												<AudioPlayer
													inline
													autoplay
													onErrorPlay={() => {
														console.log(
															'Something went wrong'
														);
													}}
													ref={(ref) => {
														this.narDuStarterAudioPlayer =
															ref;
													}}
													refs={this.audioRefs}
													src={assignment.mainSound}
												/>
												{assignment.text}
											</p>
										</div>
										<div className="lit-main-img-container">
											<img
												className={'imgMainItem'}
												src={assignment.mainImg}
											></img>
										</div>

										<div className="lit-words">
											<span className="lit-input__label lit-input__label--count">
												{id}
											</span>

											<div className="lit-word-list">
												{choices.map((choice, i) => {
													const idWord =
														i + choice.word;
													return (
														<span
															className="lit-word"
															key={idWord}
														>
															<input
																checked={
																	currentAssignmentValue ===
																	choice.word
																}
																className="lit-word__field"
																id={idWord}
																onChange={() =>
																	this.setResults(
																		id,
																		choice.word
																	)
																}
																type="radio"
															/>
															<label
																className="lit-word__label"
																htmlFor={idWord}
															>
																<div className="checkbox" />
																{/* {choice.word} */}
																<span>
																	<img
																		className={
																			'imgItem'
																		}
																		src={
																			choice.img
																		}
																	></img>
																</span>
															</label>
														</span>
													);
												})}
											</div>
										</div>

										{comment}
									</div>
								);
							}
						}
					)}
				</div>
				{isNextButtonVisible && (
					<>
						<p className="instruction">
							<AudioPlayer
								inline
								autoplay
								onErrorPlay={() => {
									console.log('Something went wrong');
								}}
								ref={(ref) => {
									this.narDuStarterAudioPlayer = ref;
								}}
								refs={this.audioRefs}
								src={this.starterTestenSound}
							/>
							{tulleordStarterTestenText}
						</p>

						<button
							className="lit-btn bg-primary"
							onClick={() => {
								onNextStep(results);
								this.props.onShowAvatar(false);
							}}
						>
							{strings.startTest}
						</button>
					</>
				)}
				{this.state.nextTutorial && count < 4 && (
					<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								showTestPanel: true,
								nextTutorial: false,
							});
							this.props.onShowAvatar(false);
						}}
					>
						{'Nästa'}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Tutorial);
