import React from 'react';

// import './styles.scss';

import {end} from '../../utils/beforeunload';
import strings from '../../utils/strings';
import ferdigLydDe from '../../assets/sounds/youth/de/testen-er-ferdig.wav';
import ferdigLydSe from '../../assets/sounds/child/se/SWSCRC-1B.mp3';
import AudioPlayer from '../AudioPlayer';

class Ferdig extends React.Component {
	constructor() {
		super();
		const language = strings.getLanguage();
		const previewAudioObj = {
			no: ferdigLydDe,
			nn: ferdigLydDe,
			se: ferdigLydSe,
			de: ferdigLydDe,
		};
		this.audio = previewAudioObj[language];
	}
	componentDidMount() {
		end();
		window.localStorage.removeItem('authtoken-screening');
	}

	render() {
		return (
			<div>
				<h1 className="lit-page-title">{strings.done}</h1>

				<p className="lit-page-description">
					{' '}
					<AudioPlayer
						autoplay
						inline
						src={this.audio}
						onErrorPlay={() => {
							console.log('Something went wrong');
						}}
					/>
					{strings.doneCongrats}
				</p>
			</div>
		);
	}
}

export default Ferdig;
